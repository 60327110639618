/**
 * 結婚式まであと○○日の画面
 */
(function () {
    this.DaysLeft = (function () {
        function DaysLeft() {
            this.init();
        }
        DaysLeft.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="days_left"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                 /**
                 * データ群
                 */
                data: function () {
                    return {
                        // 招待状ID
                        sid: JSON.parse(document.querySelector('[data-roll="sid_json"]').innerHTML),
                        // 画像URL
                        imageUrl: '',
                        // タイトル
                        title: '',
                        // フレーム
                        frame: '',
                        // アップロード画像のファイル名
                        photoFileName: '',
                        // アップロード画像の最大は15M
                        IMAGE_FILE_LIMIT_SIZE: 15,

                        newImage: null,

                        prevUrl: '',

                        baseImage: '',

                        baseUrl: '',
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // タイトルをjsonから取得
                    this.title = JSON.parse(document.querySelector('[data-roll="title_json"]').innerHTML);

                    // タイトルがcookieに保存されている場合はそちらを優先
                    var titleCookie = Cookies.get(this.sid + '.days_left.title');
                    if (titleCookie !== undefined) {
                        this.title = titleCookie;
                    }

                    // フレームがcookieに保存されている場合はそちらを優先
                    var frameCookie = Cookies.get(this.sid + '.days_left.frame');
                    if (frameCookie === undefined) {
                        this.frame = 1;
                    } else {
                        this.frame = frameCookie;
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // jQueryロード後に、画像URLを初期化
                    var _this = this;
                    $(function () {
                        _this.reloadImage();
                    });
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                    // 画像URLが変更されたらローディング
                    imageUrl: function () {
                        $.blockUI();
                    },
                },
                /**
                 * 算出プロパティ
                 */
                computed: {
                    // ダウンロード用URL
                    downloadUrl: function () {

                        return '/days_left/download?title=' + encodeURIComponent(this.title);
                    },
                    // 日本語を含むタイトルか否か
                    titleContainsJapanese: function () {
                        // asciiコードでスペースから~までをマッチすることで半角英数記号をチェック
                        return !/^[\x20-\x7e]*$/.test(this.title);
                    },
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * 画像をリロードする
                     */
                    reloadImage: function () {
                        // 素材画像
                        this.prevUrl = '/days_left/baseImage.jpg';
                        this.imageUrl = '/days_left/image.jpg?frame=' + this.frame + '&title=' + encodeURIComponent(this.title) + '&t=' + new Date().getTime();
                    },
                    /**
                     * 画像の読み込み終了後のイベント
                     */
                    imageLoaded: function () {
                        $('.image-wrapper').show();
                        $.unblockUI();
                    },
                    /**
                     * 編集用のモーダルを開く
                     */
                    openEditModal: function () {
                        $('[data-remodal-id="edit_image_modal"]').remodal({
                            hashTracking: false,
                            modifier: 'l-remodal remodal-screen-fit edit_image_modal', // classをオーバーライド
                        }).open();
                    },
                    /**
                     * 画像選択のファイルダイアログを表示
                     */
                    selectPhotoInput: function () {
                        $('.edit_image_modal input[type=file][name=photo]')[0].click();
                    },
                    /**
                     * 画像のバリデーションを行う
                     * @param input
                     * @return {string|null|boolean}
                     */
                    validatePhoto: function (input) {
                        var file = input.files[0];

                        // バリデーション処理
                        if (!file) {
                            return '対応していないファイルまたは対応していないブラウザです。ファイル形式とお使いのブラウザをご確認ください。';
                        }
                        if (file.type.length === 0 || !/^image\/(gif|jpe?g|png)$/.test(file.type)) {
                            return '対応していないファイル形式です。jpeg/gif/pngのいずれかを指定してください。';
                        }
                        if (file.size > (this.IMAGE_FILE_LIMIT_SIZE * 1024 * 1024)) {
                            return 'ファイルサイズが大きすぎます。' + this.IMAGE_FILE_LIMIT_SIZE + 'MB以下のファイルを指定してください。';
                        }

                        return true;
                    },
                    /**
                     * 画像のinputが変更された際のリスナー
                     * @param e
                     */
                    photoChanged: function (e) {
                        var input = $('.edit_image_modal input[type=file][name=photo]')[0];
                     //   this.frame = $('.edit_image_modal input[type=radio][name=frame]')[0];
                        // inputキャンセル時はスルー
                        if (input.value.length === 0) {
                            this.photoFileName = '';
                            return;
                        }
                        var validated = this.validatePhoto(input);
                        if (validated === true) {
                            // inputのファイル名が取得できた場合に文言を修正する
                            if (input && input.files) {
                                if (input.files.length > 0 && input.files[0]) {
                                    // ファイル名をバインド
                                    this.photoFileName = input.files[0].name;
                                    var reader = new FileReader();
                                    reader.onload =  (e) => {
                                        this.prevUrl = e.currentTarget.result;
                                    };
                                    reader.readAsDataURL(input.files[0]);
                                } else {
                                    this.photoFileName = '';
                                }
                            }
                        } else {
                            this.photoFileName = '';
                            input.value = ''; // ファイルの中身はクリア
                            if (validated) {
                                return vex.dialog.alert(validated);
                            }
                        }
                    },
                    /**
                     * 画像とタイトルの保存処理
                     */
                    submit: function (e) {
                        // タイトルはcookieに保存
                        Cookies.set(this.sid + '.days_left.title', this.title);
                        var input = $('.edit_image_modal input[type=file][name=photo]')[0];

                        this.frame = $('input:radio[name="frame"]:checked').val();
                        Cookies.set(this.sid + '.days_left.frame', this.frame);
                        
                        // 画像が変更されている場合は画像のアップロード
                        if (input.value.length) {
                            var validated = this.validatePhoto(input);
                            if (validated !== true) {
                                // バリデーションエラー
                                if (validated) {
                                    vex.dialog.alert(validated);
                                }
                                return;
                            }
                            // アップロード処理
                            var file = input.files[0];
                            $.blockUI();
                            var formData = new FormData();
                            formData.append('photo', file);
                            // 画像をajaxアップロード
                            $.ajax('/days_left/upload', {
                                type: 'POST',
                                data: formData,
                                dataType: 'json',
                                cache: false,
                                timeout: 300000, // 5分
                                processData: false,
                                contentType: false
                            }).done(function (data, textStatus, xhr) {
                                if (data.status === 'OK') {
                                    // 画面リロード
-                                    location.reload(true);
                                } else {
                                    vex.dialog.alert(data.errors[0]);
                                }
                            }).fail(function (xhr, textStatus, errorThrown) {
                                $.unblockUI();
                                if (xhr.status === 401) {
                                    location.reload(true);
                                } else {
                                    // サーバーエラー
                                    vex.dialog.alert('画像のアップロードに失敗しました。もう一度お試しください');
                                }
                            }).always(function() {
                                input.value = ''; // ファイルの中身はクリア
                                $.unblockUI();
                            });
                        } else {
                        // 画像が変わっていない場合はそのままリロード
                        location.reload(true);
                        }
                    },
                }
            });
        };

        return DaysLeft;
    })();
}).call(this);
