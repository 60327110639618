/**
 * お礼状の内容編集画面
 */
(function () {
    this.ThanksLetterContent = (function () {
        function ThanksLetterContent() {
            this.init();
        }

        ThanksLetterContent.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="thanks_letter_content"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        Common: null, // Commonオブジェクト
                        sid: '', // 招待状ID
                        tab: 'picture', // 表示中のタブ（picture|video）
                        imageList1: [], // 画像1のリスト
                        imageList2: [], // 画像2のリスト
                        videoUrl: '',   // 動画のURL
                        videoPlayerUrl: '', // 動画プレイヤーのiframe用URL
                        youtubePcUrlPattern: /^https:\/\/www\.youtube\.com\/watch\?.+$/,   // YouTubeのURLパターン（PC）
                        youtubeMobileUrlPattern: /^https:\/\/m\.youtube\.com\/watch\?.+$/, // YouTubeのURLパターン（モバイル）
                        youtubeShortUrlPattern: /^https:\/\/youtu\.be\/.+$/,               // YouTubeのURLパターン（リンク用URL）
                        youtubeShortVideoUrlPattern: /^https:\/\/(www\.)?youtube\.com\/shorts\/.+$/, // YouTubeのショート動画URLパターン
                        croppingPosition: null, // クロッピング中の画像種別（1|2）
                        croppingIndex: null, // クロッピング中の画像リスト内のindex
                        croppingData: {}, // クロッピング中の情報
                        IMAGE_LIMIT_COUNT_1: 10, // 画像1の最大枚数
                        IMAGE_LIMIT_COUNT_2: 30, // 画像2の最大枚数
                        IMAGE_FILE_LIMIT_SIZE: 15, // アップロード画像の最大は15M
                        errors: [],
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    this.sid = document.querySelector('[data-role="sid"]').innerHTML;
                    // お礼状にひも付くメディアを振り分ける
                    var medias = JSON.parse(document.querySelector('[data-role="medias_json"]').innerHTML);
                    for (var i = 0; i < medias.length; i++) {
                        var media = medias[i];
                        switch (media.type) {
                            case 'image_1':
                                this.imageList1.push({
                                    url: media.content,
                                });
                                break;
                            case 'image_2':
                                this.imageList2.push({
                                    url: media.content,
                                });
                                break;
                            case 'video':
                                this.videoUrl = media.content;
                                this.reloadVideoPlayer();
                                break;
                        }
                    }

                    // タブ切り替え
                    this.tab = !!this.videoUrl ? 'video' : 'picture';
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    this.Common = new Common();

                    $.ajaxSetup({
                        type: 'POST',
                        dataType: 'json',
                        cache: false,
                        timeout: 300000, // 5分
                    });
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * フォームのsubmit処理
                     */
                    submit: function () {
                        var _this = this;
                        var $form = this.$el.querySelector('#update_form');

                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってから処理を行う
                        Vue.nextTick(function () {
                            if (_this.validate($form)) {
                                _this.Common.ajaxSubmit('/thanks_letter_content/update', 'post', $($form).serialize(), {
                                    // エラー処理
                                    error: function(data, textStatus, xhr) {
                                        _this.errors = data.errors;
                                        swalToast('エラーがあります', {icon: 'warning'});
                                    },
                                });
                            }
                        });
                    },

                    /**
                     * バリデーションを行う
                     */
                    validate: function ($form) {
                        this.errors = [];

                        var topImageTypeRadio = $form.querySelector('input[name="top_image_type"]:checked');
                        if (!topImageTypeRadio || validator.isBlank(topImageTypeRadio.value)) {
                            this.errors.push('トップに表示するイメージを選択してください');
                        }
                        if (topImageTypeRadio.value === 'picture') {
                            if (!$form.querySelector('input[name="image_1_urls[]"]')) {
                                this.errors.push('トップに表示するイメージ画像を選択してください');
                            }
                        } else {
                            if (validator.isBlank($form.querySelector('input[name="video_url"]').value)) {
                                this.errors.push('YouTubeのURLを入力してください');

                            // YouTubeのURLは自動でPC用に置換するため、validateではPC用URLのみを許可する
                            } else if (!this.youtubePcUrlPattern.test(this.videoUrl)) {
                                this.errors.push('YouTubeのURLを入力してください');
                            }
                        }

                        return this.errors.length === 0;
                    },

                    /**
                     * 画像アップロード用のダイアログを表示
                     * @param imagePosition
                     * @param imageIndex
                     */
                    openImageUploadDialog: function (imagePosition, imageIndex) {
                        // クロッピング中の値にセット
                        this.croppingPosition = imagePosition;
                        if (imageIndex !== undefined) {
                            this.croppingIndex = imageIndex;
                        } else {
                            this.croppingIndex = null;
                        }

                        // ファイルダイアログ表示
                        var $form = this.$el.querySelector('#upload_for_crop_form');
                        $form.querySelector('input[name="upload_file"]').click();
                    },

                    /**
                     * 画像の削除
                     * @param imagePosition
                     * @param imageIndex
                     */
                    removeImage: function (imagePosition, imageIndex) {
                        this['imageList' + imagePosition].splice(imageIndex, 1);
                    },

                    /**
                     * クロッピング用に画像をアップロードする
                     * @param e
                     */
                    uploadForCrop: function (e) {
                        var _this = this;
                        var input = e.currentTarget;
                        // inputキャンセル時はスルー
                        if (input.value.length === 0) {
                            return;
                        }
                        // バリデーション処理
                        var file = input.files[0];
                        if (!file) {
                            return vex.dialog.alert('対応していないファイルまたは対応していないブラウザです。ファイル形式とお使いのブラウザをご確認ください。');
                        } else {
                            if (file.type.length === 0 || !/^image\/(gif|jpe?g|png)$/.test(file.type)) {
                                return vex.dialog.alert('対応していないファイル形式です。jpeg/gif/pngのいずれかを指定してください。');
                            }
                            if (file.size > (this.IMAGE_FILE_LIMIT_SIZE * 1024 * 1024)) {
                                return vex.dialog.alert('ファイルサイズが大きすぎます。' + this.IMAGE_FILE_LIMIT_SIZE + 'MB以下のファイルを指定してください。');
                            }
                        }
                        // アップロード用プログレスバー表示
                        $.blockUI({
                            message: '<div class="progress_bar_wrapper"><div id="progress_bar" class="progress_bar" style="width: 0%;">0%</div></div>',
                        });

                        // 画像をajaxアップロード
                        $.ajax('/thanks_letter_content/upload_for_crop', {
                            data: new FormData(this.$el.querySelector('#upload_for_crop_form')),
                            processData: false,
                            contentType: false,
                            // プログレスバーの表示
                            xhr : function(){
                                var XHR = $.ajaxSettings.xhr();
                                if(XHR.upload){
                                    XHR.upload.addEventListener('progress', function (data){
                                        var progress = Math.ceil(data.loaded / data.total * 100);
                                        $('#progress_bar').css('width', progress + '%').text(progress + '%');
                                        if (Number(progress) === 100) {
                                            $('#progress_bar').text('画像処理中...');
                                        }
                                    }, false);
                                }
                                return XHR;
                            }
                        }).done(function (data, textStatus, xhr) {
                            $.unblockUI();
                            if (data.status === 'OK') {
                                Vue.set(_this.croppingData, 'url', data.url + '?t=' + Date.now());

                                // クロッピング領域を生成して表示
                                $.blockUI({
                                    message: $('#crop_box'),
                                    css: {
                                        cursor: 'default',
                                        top: '2%',
                                        left: '2%',
                                        width: '96%',
                                        height: '96%'
                                    },
                                    overlayCSS: {
                                        backgroundColor: '#000',
                                        opacity: 0.5
                                    },
                                    onBlock: function () {
                                        // 画像の領域調整
                                        $('#crop_box img').css("max-height", $(this).height() - 55 + 'px');
                                        // すでにあるcropperを除去し、再度cropper表示
                                        $('#crop_box img').cropper('destroy');
                                        $('#crop_box img').cropper({
                                            aspectRatio: 1, // 正方形
                                            rotatable: false,
                                            autoCropArea: 1, // クロップ範囲の初期値は100%で
                                            zoomOnWheel: false, // マウスホイールでのズームOFF
                                            zoomOnTouch: false, // スマホのピンチでのズームOFF
                                        });
                                        // 画像上部OR下部にCSSで補正できない隙間をなくすため、わずかにズームさせる
                                        $('#crop_box img').on('built.cropper', function(){
                                            $('#crop_box .crop_img').cropper('zoom', 0.03);
                                        });
                                    }
                                });
                            } else {
                                vex.dialog.alert(data.errors[0]);
                            }
                        }).fail(function (xhr, textStatus, errorThrown) {
                            $.unblockUI();
                            if (xhr.status === 401) {
                                // ログインエラーの場合は、セッションタイムアウトとして処理
                                _this.sessionTimeout();
                            } else {
                                // サーバーエラー
                                vex.dialog.alert('画像のアップロードに失敗しました。もう一度お試しください');
                            }
                        }).always(function() {
                            input.value = ''; // ファイルの中身はクリア
                        });
                    },

                    /**
                     * クロッピングのキャンセル処理
                     */
                    cropCancel: function () {
                        $.unblockUI();
                        this.croppingData = {};
                    },
                    /**
                     * クロッピングの確定処理
                     */
                    cropSubmit: function () {
                        var _this = this;
                        var data = $('#crop_box .crop_img').cropper('getData');
                        // cropperのデータがまだセットされていないときはsubmitさせない
                        if (!data.width || !data.height) {
                            return;
                        }

                        Vue.set(this.croppingData, 'x', Math.round(data.x));
                        Vue.set(this.croppingData, 'y', Math.round(data.y));
                        Vue.set(this.croppingData, 'w', Math.round(data.width));
                        Vue.set(this.croppingData, 'h', Math.round(data.height));

                        this.Common.ajaxSubmit('/thanks_letter_content/submit_crop', 'post', this.croppingData, {
                            // クロッピングされた画像のURLをバインドする
                            success: function(data, textStatus, xhr) {
                                if (_this.croppingIndex !== null) {
                                    // indexがあればそのURLを差し替え
                                    _this['imageList' + _this.croppingPosition][_this.croppingIndex].url = data.url;
                                } else {
                                    // indexが無ければリストに追加
                                    _this['imageList' + _this.croppingPosition].push({
                                        url: data.url,
                                    });
                                }
                            },
                            fail: function(message) {
                                vex.dialog.alert('画像のアップロードに失敗しました。もう一度お試しください');
                            }
                        });
                    },

                    /**
                     * YouTubeのプレーヤーをリロードする
                     */
                    reloadVideoPlayer: function () {
                        var videoId = '';
                        // YouTubeの動画のURLパターンごとにvideoIdを取得
                        if (this.youtubePcUrlPattern.test(this.videoUrl)) {
                            const params = (new URL(this.videoUrl)).searchParams;
                            videoId = params.get('v');
                        } else if (this.youtubeMobileUrlPattern.test(this.videoUrl)) {
                            const params = (new URL(this.videoUrl)).searchParams;
                            videoId = params.get('v');
                        } else if (this.youtubeShortUrlPattern.test(this.videoUrl)) {
                            const path = (new URL(this.videoUrl)).pathname;
                            videoId = path.replace(/^\/([^\/?&]+).*$/, '$1');
                        } else if (this.youtubeShortVideoUrlPattern.test(this.videoUrl)) {
                            let path = (new URL(this.videoUrl)).pathname;
                            path = path.replace(/^\/shorts/, '');
                            videoId = path.replace(/^\/([^\/?&]+).*$/, '$1');
                        }

                        if (!videoId) {
                            this.videoPlayerUrl = '';
                            vex.dialog.alert('YouTubeの動画URLを入力してください');
                            return;
                        }

                        // YouTubeのURLは自動でPC用に変換する（秒数指定等の他のパラメータは無視する）
                        this.videoUrl = 'https://www.youtube.com/watch?v=' + videoId;

                        var origin = location.protocol + '//' + location.hostname;
                        this.videoPlayerUrl = 'https://www.youtube.com/embed/' + videoId + '?origin=' + origin;
                    },

                    /**
                     * セッションタイムアウト処理
                     */
                    sessionTimeout: function () {
                        vex.dialog.alert({
                            message: 'セッションが切れました。再度ログインしてください。',
                            callback: function () {
                                location.reload(true);
                            }
                        });
                    },
                }
            });
        };

        return ThanksLetterContent;
    })();
}).call(this);
